import { useState, useEffect } from "react";
import axios from "axios";

export function useCheckAccess() {
  const [disableRecording, setDisableRecording] = useState(false);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await axios.options("/api/v1/recorder/session/start");

        if (response.status !== 204) {
          setDisableRecording(true);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Axios error:", error);
          if (error.response?.status === 401) {
            setDisableRecording(true);
          }
        } else if (error instanceof Error) {
          console.error("Non-Axios error:", error.message);
        } else {
          console.error("Unknown error:", error);
        }
      }
    };

    checkAccess();
  }, []);

  return disableRecording;
}
