import { S3PresignedUrl } from "../models/session";

export async function uploadFileToS3(
  arrayBuffer: BlobPart[],
  s3PresignedUrl: S3PresignedUrl
) {
  const blob = new Blob(arrayBuffer, { type: "audio/ogg; codecs=opus" });

  const formData = new FormData();
  Object.entries(s3PresignedUrl.fields).forEach(([key, value]) => {
    formData.append(key, value as string);
  });

  formData.append("file", blob);
  try {
    const response = await fetch(s3PresignedUrl.url, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      console.log("File uploaded successfully");
    } else {
      console.error("Failed to upload file", response.statusText);
      throw new Error("error while uploading file");
    }
  } catch (error) {
    console.error("Error during file upload", error);
    throw new Error("error while uploading file");
  }
}
