import type { AxiosRequestConfig } from 'axios';
import axios, { AxiosError } from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export const responseInterceptor = async (
  error: AxiosError,
  auth0: Auth0ContextInterface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const originalRequest = error.config as ExtendedAxiosRequestConfig;

  if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      const newToken = await auth0.getAccessTokenSilently();

      if (originalRequest.headers) {
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
      }

      return axios.request(originalRequest);
    } catch (refreshError) {
      return Promise.reject(refreshError);
    }
  }

  return Promise.reject(error);
};
