import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

const LoginComponent: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const login = async () => {
    await loginWithRedirect();
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/recorder');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      <Button onClick={login} className="bg-red-500 hover:bg-red-600">LOG IN</Button>
    </div>
  );
};

export default LoginComponent;
