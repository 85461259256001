import React from "react";

interface SpinnerProps {
  size?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 80 }) => {
  return (
    <div className="flex flex-col h-screen gradient-bg text-white justify-center items-center">
      <div className="h-20 w-20 animate-spin">
        <svg
          width={`${size}px`}
          height={`${size}px`}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 1V5"
            stroke="#DF1463"
            strokeWidth="1.7"
            strokeLinecap="round"
          />
          <path
            d="M19.4246 18.9246L16.5961 16.0962"
            stroke="#ffffff"
            strokeWidth="1.7"
            strokeLinecap="round"
          />
          <path
            d="M22.5 11.5L18.5 11.5"
            stroke="#ffffff"
            strokeWidth="1.7"
            strokeLinecap="round"
          />
          <path
            d="M12 18V22"
            stroke="#ffffff"
            strokeWidth="1.7"
            strokeLinecap="round"
          />
          <path
            d="M7.40381 6.90381L4.57538 4.07538"
            stroke="#ffffff"
            strokeWidth="1.7"
            strokeLinecap="round"
          />
          <path
            d="M5.5 11.5L1.5 11.5"
            stroke="#ffffff"
            strokeWidth="1.7"
            strokeLinecap="round"
          />
          <path
            d="M7.40381 16.0962L4.57538 18.9246"
            stroke="#ffffff"
            strokeWidth="1.7"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default Spinner;
