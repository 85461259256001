// components/Toast.tsx
import * as ToastPrimitive from '@radix-ui/react-toast';
import { CheckCircle, CircleAlert } from 'lucide-react';

interface ToastProps {
  title: string;
  description?: string;
  type?: 'success' | 'error';
  onOpenChange?: (open: boolean) => void;
  open: boolean;
}

export const Toast: React.FC<ToastProps> = ({
  title,
  description,
  type = 'success',
  onOpenChange,
  open,
}) => {
  return (
    <ToastPrimitive.Provider>
      <ToastPrimitive.Root
        open={open}
        onOpenChange={onOpenChange}
        className={`fixed bottom-10 right-0 left-0 m-4 p-4 rounded-lg shadow-lg text-white z-100 ${
          type === 'success' ? 'bg-green-600' : 'bg-red-800'
        }`}
      >
        <div className="flex items-center">
          {type === 'success' ? (
            <CheckCircle className="mr-2 text-white" />
          ) : (
            <CircleAlert className="mr-2 text-white" />
          )}
          <div>
            <ToastPrimitive.Title className="font-bold">
              {title}
            </ToastPrimitive.Title>
            {description && (
              <ToastPrimitive.Description>
                {description}
              </ToastPrimitive.Description>
            )}
          </div>
        </div>
      </ToastPrimitive.Root>
      <ToastPrimitive.Viewport />
    </ToastPrimitive.Provider>
  );
};
