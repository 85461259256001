import React from "react";
import { Button } from "./ui/button";
import { Download } from "lucide-react";

interface LocalUploadProps {
  chunks: Blob[];
}

const LocalUpload: React.FC<LocalUploadProps> = ({ chunks }) => {
  const handleDownload = () => {
    if (chunks.length > 0) {
      const recordedBlob = new Blob(chunks, { type: "audio/webm; codecs=opus" });
      const url = URL.createObjectURL(recordedBlob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "recording.ogg";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <Button
        size="iconBig"
        variant="outline"
        onClick={handleDownload}
        className="text-white bg-transparent rounded-full"
      >
        <Download size={24} color="white" />
      </Button>
      <span className="text-sm mt-1">Local upload available</span>
    </div>
  );
};

export default LocalUpload;
