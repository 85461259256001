import type { InternalAxiosRequestConfig } from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';

export const requestInterceptor = async (
  config: InternalAxiosRequestConfig,
  auth0: Auth0ContextInterface,
  errorCallBack: (error: unknown) => void
): Promise<InternalAxiosRequestConfig> => {
  try {
    const token = await auth0.getAccessTokenSilently();

    config.headers.Authorization = `Bearer ${token}`;
  } catch (error) {
    errorCallBack(error);
  }
  return config;
};
