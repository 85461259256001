import React from "react";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Download } from "lucide-react";
import { Button } from "./ui/button";

interface RecordedFilesCardProps {
  audioUrl: string | null;
  audioBlob?: Blob | null;
}

const RecordedFilesCard: React.FC<RecordedFilesCardProps> = ({
  audioUrl,
  audioBlob,
}) => {
  const handleDownload = () => {
    if (audioBlob) {
      const url = window.URL.createObjectURL(audioBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `recording-${new Date().toLocaleDateString()}.ogg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Card className="glass-effect text-white">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Recorded File</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        {audioUrl && (
          <div className="flex items-center justify-around gap-2">
            <audio
              controls
              preload="auto"
              controlsList="nodownload"
            >
              <source src={audioUrl} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
            {audioBlob && (
              <div className="flex flex-col items-center">
                <Button
                  size="iconBig"
                  variant="outline"
                  onClick={handleDownload}
                  className="text-white bg-transparent rounded-full"
                >
                  <Download size={24} color="white" />
                </Button>
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default RecordedFilesCard;
