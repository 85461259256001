import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { requestInterceptor } from '@/api/interceptors/request.interceptor';
import { responseInterceptor } from '@/api/interceptors/response.interceptor';

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

axios.defaults.baseURL = apiBaseUrl;

export const setupAxiosInterceptors = (auth0: Auth0ContextInterface) => {
  axios.interceptors.request.use(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (config) => requestInterceptor(config, auth0, () => {
      handleUnauthorizedUserNavigation(auth0.isAuthenticated);
    })
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => responseInterceptor(error, auth0)
  );
};

const handleUnauthorizedUserNavigation = async (isAuthenticated: boolean) => {
  if (!isAuthenticated) {
    window.localStorage.setItem('notifyUserAutoLogout', 'true');
    window.location.href = '/login';
  }
};
