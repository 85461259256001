import React from "react";
import LoginButton from "@/components/LoginButton";

const Login: React.FC = () => {
  return (
    <main className="gradient-bg h-screen w-screen flex justify-center items-center">
      <div className="p-8 rounded-sm shadow-ms flex justify-center items-center bg-white m-4">
        <div className="p-6 pt-0 text-center">
          <h2 className="text-2xl font-bold mb-6">Welcome to Megu Recorder</h2>
          <p className="text-gray-500 mb-4">Sign in to your account</p>
          <LoginButton />
        </div>
       
      </div>
    </main>
  );
};

export default Login;
