import Dexie, { Table } from "dexie";
import { DATABASE_NAME } from "@/contants";

export interface AudioChunk {
  id?: number;
  chunk: Blob;
}

class AudioDatabase extends Dexie {
  audioChunks!: Table<AudioChunk>;

  constructor() {
    super(DATABASE_NAME);
    this.version(1).stores({
      audioChunks: "++id,chunk",
    });
  }
}

const db = new AudioDatabase();

export async function getAudioChunks() {
  return await db.audioChunks.toArray();
}

export async function addAudioChunk(chunk: Blob): Promise<number> {
  return await db.audioChunks.add({ chunk });
}

export async function clearAllChunks() {
  await db.audioChunks.clear();
}

export async function deleteDatabase(databaseName: string) {
  try {
    const db = new Dexie(databaseName);

    await db.delete();
  } catch (error) {
    console.error(`Error deleting database '${databaseName}':`, error);
  }
}

export default db;
