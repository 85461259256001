import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuth0 } from "@auth0/auth0-react";
import { UserIcon } from "lucide-react";

const UserAvatar = () => {
  const { user } = useAuth0();

  return (
    <Avatar className="h-8 w-8 cursor-pointer">
      {user?.picture ? (
        <AvatarImage alt="Picture" src={user?.picture || ""} />
      ) : (
        <AvatarFallback>
          <span className="sr-only">{user?.name}</span>
          <UserIcon className="h-4 w-4" />
        </AvatarFallback>
      )}
    </Avatar>
  );
};

export default UserAvatar;
