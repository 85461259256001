export interface IModal {
  action?: string;
  open: boolean;
  title?: string;
  paragraph?: string;
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
  secondButtonVisible?: boolean;
  secondButtonAction?: () => void;
  closeButtonVisible?: boolean;
}

export enum ReasonType {
  CANCEL = "cancel",
  SAVE = "save",
  DELETE = "delete",
}

export type Reason = ReasonType.CANCEL | ReasonType.SAVE | ReasonType.DELETE;
